@import '../../mai-styles/';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

@media(max-width: 1080px){
  html{
      font-size: 93.75%;
  }
}

@media(max-width: 720px){
  html{
      font-size: 87.5%;
  }
}
